<template>
  <!-- 视频工具 -->
  <div class="videoBox" v-if="listVideoAdverts">
    <!-- 视频 -->
    <div class="videoToolBox" v-if="listVideoAdverts.length > 0">
      <!-- <img class="videoMain" src="~assets/img/chome/video.png" /> -->
      <video-item :videoUrl="listVideoAdverts[0].ImgUrl" />
    </div>
  </div>
</template>
<script>
import VideoItem from "@/components/common/VideoItem";
// import ShopLogo from './ShopLogo'
export default {
  name: "VideoTool",
  components: {
    VideoItem,
    // ShopLogo
  },
  props: {
    listVideoAdverts: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.shopBox {
  padding: 0 10px;
  box-sizing: border-box;
}
// 视频
.videoBox {
  margin-top: 20px;
  .videoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .videoTitleLeft {
      color: #000000;
      font-size: 15px;
      font-family: Helvetica85-Heavy;
      font-weight: 800;
    }
  }
  .videoToolBox {
    position: relative;
    .videoMain {
      width: 100%;
    }
  }
}
.commontitle {
  font-size: 14px;
  font-family: HelveticaNeue LT 97 BlackCn;
  font-weight: 400;
  color: #1eb326;
  text-align: left;
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  -webkit-display: flex;
  -webkit-align-items: center;
  -webkit-flex-direction: row-reverse;
}
</style>
