<template>
  <div class="iconList" v-if="listHotClass">
    <div
      class="iconItem"
      v-for="(item, index) in listHotClass"
      :key="index"
      @click="getSearch(item.HotClassId, item.ClassName)"
    >
      <van-image class="iconImg" :src="imgUrlFilter(item.Icon)">
        <template v-slot:error>
          <img class="ImgIcon" :src="iconList[index].imgUrl" />
        </template>
      </van-image>

      <!-- <img class="iconImg" :src="imgUrlFilter(item.Icon) | imageSrc" /> -->

      <div class="iconTitle">{{ item.ClassName }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IconList",
  components: {},
  props: {
    listHotClass: {
      type: Array,
    },
  },
  data() {
    return {
      iconList: [
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_gift.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_house.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_clock.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_phone.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_bag.png"),
          name: "vacheeen",
        },
      ],
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    // 跳转详情页
    getSearch(SreachValues, ClassName) {
      if (SreachValues.toString() === "-999") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "allProducts",
          name: "AllProducts",
          query: [],
        });
        return false;
      }
      console.log(SreachValues);
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "searchClass",
        name: "SearchClass",
        query: [
          {
            name: "ClassName",
            value: ClassName,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
// 是设计图是三杯图
.iconList {
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 21px;
  padding: 0 4.13%;
  box-sizing: border-box;
  @include publicFlex;
  .iconItem {
    text-align: center;
    .iconImg {
      width: 45px;
      margin-bottom: 17px;
      .ImgIcon {
        width: 45px;
      }
      ::v-deep .van-image__img {
        width: 45px;
      }
      ::v-deep .van-image__error {
        width: 45px;
      }
    }
    .iconTitle {
      font-size: 0.7rem;
      font-family: Helvetica;
      font-weight: 400;
      color: #000000;
      width: 60px;
      text-align: center;
      margin-top: 5px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;
    }
  }
}
</style>
