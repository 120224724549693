<template>
  <div
    class="shopListItemMain"
    v-if="item"
    @click="getProductDetails(item.ProductNumber, item.ShopId)"
  >
    <figure class="shopImgBox">
      <van-image class="shopImg" :src="item.ProductPhoto | imgUrlFilter">
        <template v-slot:error>
          <img class="ImgIcon" src="~assets/img/other/empty.gif" />
        </template>
      </van-image>
    </figure>
    <div class="shopInfo">
      <div class="discount" v-if="item.IsCouponProduct">
        {{ $t.discount }}
      </div>
      <span class="name" v-if="item.TProductName">{{ item.TProductName }}</span
      ><span class="name" v-else>{{ item.ProductName }}</span>
    </div>
    <p class="shopName">USD $ {{ item.MinPrice }}</p>
    <section class="logoBox">
      <van-image class="shopImg" :src="item.ShopHeadPhoto | imgUrlFilter">
        <template v-slot:error>
          <img class="ImgIcon" src="~assets/img/userManage/img_logo@2x.png" />
        </template>
      </van-image>

      <!-- <img class="logoImg" src="~assets/img/chome/o.png" /> -->
      <span class="shopTitle">{{ item.ShopName }}</span>
    </section>
    <div class="shopBotBox">
      <figure class="cartImg">
        <img
          class="cartImgIcon"
          src="~assets/img/chome/new/icon_vancheencar@2x.png"
        />
      </figure>
      <div class="shopBotRight">...</div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Image } from "vant";
Vue.use(Image);

export default {
  name: "ShopItem",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.shopBox {
  padding: 0 12px;
  box-sizing: border-box;
}
.shopListItemMain {
  box-shadow: 0px 2.5px 6.5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  .shopImgBox {
    padding: 0;
    margin: 0;
    height: 44.4vw;
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .shopImg {
      width: 100%;
      height: 100%;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      object-fit: cover;
      .ImgIcon {
        width: 100%;
        height: 100%;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        object-fit: cover;
      }
      ::v-deep .van-image__img {
        width: 100%;
        height: 100%;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        position: relative;
        object-fit: cover;
      }
      ::v-deep .van-image__error {
        width: 100%;
        height: 100%;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        position: relative;
        object-fit: cover;
      }
    }
  }
  .shopInfo {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: normal;
    color: #000000;
    font-size: 12px;
    font-family: Helvetica;
    font-weight: 400;
    margin-top: 10px;
    height: 32px;
    .discount {
      vertical-align: middle;
      display: inline-block;
      background: red;
      color: white;
      padding: 0px 2px;
      border-radius: 2px;
      font-size: 12px;
      margin-right: 5px;
    }
    .name {
      display: inline-block;
      vertical-align: middle;
    }
    @extend .shopBox;
  }
  .shopName {
    margin: 0;
    @extend .shopBox;
    font-size: 12.5px;
    font-family: Helvetica;
    font-weight: bold;
    color: #000000;
    font-weight: bold;
    margin-top: 2%;
  }
  .logoBox {
    align-items: center;
    margin-top: 4%;
    @extend .shopBox;
    @include publicFlex;
    .shopTitle {
      font-size: 12px;
      font-family: Helvetica85-Heavy;
      font-weight: 800;
      margin-left: 6px;
      color: #000000;
    }
    .shopImg {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      object-fit: cover;
      .ImgIcon {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        object-fit: cover;
      }
      ::v-deep .van-image__img {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        position: relative;
        object-fit: cover;
      }
      ::v-deep .van-image__error {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        position: relative;
        object-fit: cover;
      }
    }
  }
  .shopBotBox {
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
    padding-bottom: 3%;
    @include publicFlex;
    @extend .shopBox;
    .cartImg {
      margin: 0px;
      padding: 0px;
      @include publicFlex;
      .cartImgIcon {
        // width: (100px/3);
        width: 55px;
      }
    }
    .shopBotRight {
      color: #9fa6b0;
      font-size: 0.9rem;
    }
  }
}
</style>
