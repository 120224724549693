<template>
  <div class="categoryBox" v-if="hotListProduct && hotListProduct.length > 0">
    <div class="categoryTitle">
      <div class="categoryTitleLeft">{{ $t.meta.morePopular }}</div>
      <div class="commontitle" @click="goSearch">{{ $t.meta.more }}</div>
    </div>
    <!-- 类目列表 -->
    <div class="categoryList">
      <figure
        class="categoryItem"
        v-for="(item, index) in hotListProduct"
        :key="index"
      >
        <img
          :src="imgUrlFilter(item.ImgUrl, index)"
          class="categoryIcon"
          @click="getProductDetails(item.LinkStu, item.OtherLinkStu)"
        />
      </figure>
    </div>
  </div>
</template>
<script>
// import ShopLogo from './ShopLogo'
export default {
  name: "Category",
  components: {
    // ShopLogo
  },
  props: {
    hotListProduct: {
      type: Array,
    },
  },
  data() {
    return {
      list: [1, 2, 3],
    };
  },
  methods: {
    imgUrlFilter(value, index) {
      return this.$api.formatImageUrl(value.split("%7C")[0]);
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    goSearch() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "title",
            value: "DISCOUNT",
          },
          {
            name: "IsCouponProduct",
            value: 1,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
// 是设计图是三杯图
// 主要类目
.categoryBox {
  position: relative;
  margin-top: 25px;
  .categoryTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .categoryTitleLeft {
      font-size: 15px;
      font-family: Helvetica85-Heavy;
      font-weight: 800;
      color: #000000;
    }
  }
  .categoryList {
    position: relative;
    // margin-top: 20px;
    margin-top: 15px;
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    -webkit-overflow-scrolling: touch;
    @include publicFlex;
    .categoryItem {
      padding: 0;
      margin: 0;
      width: 33.3%;
      // height: (1010px/6);
      height: 0;
      padding-bottom: 33.3%;
      overflow: hidden;
      object-fit: cover;

      .categoryIcon {
        width: 100%;
        object-fit: cover;
        // height: 100%;
      }
    }
  }
}
.commontitle {
  font-size: 14px;
  font-family: HelveticaNeue LT 97 BlackCn;
  font-weight: 400;
  color: #1eb326;
  text-align: left;
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  -webkit-display: flex;
  -webkit-align-items: center;
  -webkit-flex-direction: row-reverse;
}
</style>
