<template>
  <div class="HomeBox">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <!-- 搜索 -->
      <div class="searchBox">
        <div class="searchInfo" @click="searchProduct">
          <div class="searchText">{{ $t.meta.search }}</div>
          <figure class="searchLogo">
            <img
              class="searchLogoIcon"
              src="~assets/img/client/icon_home_search@2x.png"
            />
          </figure>
        </div>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
      >
        <!-- banner -->
        <banner :listViewAdverts="listViewAdverts" />
        <!-- 消息通告 -->
        <div class="annunciateBox" @click="getNewsCenter" v-if="msgInfo && msgInfo.Content">
          <div class="commontitle">{{ $t.meta.newsCenter }}</div>
          <!-- <shop-logo /> -->
          <!-- 通告详情 -->
          <div class="annunciateInfo">
            <figure class="annunciateBg">
              <img class="annunciateBgImg" src="~assets/img/chome/k.png" />
            </figure>
            <p
              class="annunciateMsg"
              v-html="msgInfo.Content && strReplace(msgInfo.Content)"
            ></p>
          </div>
        </div>
        <!-- iconList图片logo -->
        <icon-list :listHotClass="listHotClass" />
        <!-- 热销 -->
        <category :hotListProduct="hotListProduct" />
        <!-- 视频工具 -->
        <video-tool :listVideoAdverts="listVideoAdverts" />
        <!-- 商品列表 -->
        <div class="shopListBox" v-if="allListProduct && allListProduct.length>0">
          <div class="shopListTitle">
            <div class="shopListTitleLeft">{{ $t.meta.newArrival }}</div>
            <div class="commontitle">{{ $t.more }}</div>
            <div class="shopListTitleRight" @click="search">
              {{ $t.meta.more }}
            </div>
          </div>
          <ul class="shopList">
            <!-- 商品对象 -->
            <li
              class="shopListItem"
              v-for="(item, index) in allListProduct"
              :key="index"
            >
              <shop-item :item="item" />
            </li>
          </ul>
        </div>
        <placeholder v-if="isPlaceholder"></placeholder>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
// import { Swipe, SwipeItem } from 'vant'
// import ShopLogo from './components/ShopLogo'
import ShopItem from "@/components/home/ShopItem";
// import VideoItem from '@/components/common/VideoItem'
import VideoTool from "./components/VideoTool";
import Banner from "./components/Banner";
import IconList from "./components/IconList";
import Category from "./components/Category";
import Placeholder from "@components/common/Placeholder";
import { isAPP } from "@/plugins/environment/type.js";
import Vue from "vue";
import { Sticky, List, PullRefresh } from "vant";
Vue.use(Sticky).use(List);

export default {
  name: "Home",
  components: {
    Banner,
    ShopItem,
    VideoTool,
    IconList,
    Category,
    Placeholder,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      env: isAPP(), // 当前环境
      isPlaceholder: false, // 全部商品是否有数据
      refresh: false,
      loading: false,
      finished: true,
      pageIndex: 1,
      indexData: {}, // 数据
      listViewAdverts: [],
      listVideoAdverts: [],
      listHotClass: [],
      msgInfo: {},
      hotListProduct: [],
      allListProduct: [],
      list: [1, 2, 3],
      iconList: [
        {
          imgUrl: require("@/assets/img/chome/a.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/b.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/c.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/e.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/e.png"),
          name: "vacheeen",
        },
      ],
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    },
  },
  beforeCreate() {
    this.$commonMethod.setBackgroundColor("#f6f6f6");
  },
  mounted() {
    this.loadIndex();
  },
  methods: {
    search() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "title",
            value: "ALL PROMOTIONS"
          },
        ],
      });
    },
    searchProduct() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "searchHistory",
        name: "search",
        query: [],
      });
    },
    getNewsCenter() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "messages",
        name: "Messages",
        query: [],
      });
    },
    strReplace(value) {
      return value.replace(/\\n/gm, "<br/>");
    },
    onRefresh() {
      this.refresh = true;
      this.loadIndex(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    // 获取搜索页数据
    loadIndex(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonMethod.showLoading();
        }
        let param = {};
        this.$api.index
          .newLoadAppIndex(param)
          .then((res) => {
            this.indexData = res.data;
            this.listViewAdverts = res.data.ListViewAdverts;
            this.listVideoAdverts = res.data.ListVideoAdverts;
            this.listHotClass = res.data.ListHotClass;
            this.msgInfo = res.data.Msg;
            this.hotListProduct = res.data.HotListProduct;
            this.allListProduct = res.data.AllListProduct.BannerListProduct;
            let allListProduct = res.data.AllListProduct.BannerListProduct;
            this.loading = false;
            if (allListProduct.length === 0 || allListProduct == null) {
              this.isPlaceholder = true;
            } else {
              this.isPlaceholder = false;
              this.finished = false;
            }
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            resolve();
          })
          .catch((error) => {
            this.loading = false;
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showDialog,
              {
                msg: error.message,
                status: error.status,
              }
            );
            reject();
          });
      });
    },
    onLoad() {
      let param = {
        PageIndex: this.pageIndex + 1,
        order: 1,
      };
      this.$api.index
        .loadCProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data == null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.allListProduct = this.allListProduct.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.privacy-alert {
  padding: 5%;
  font-size: 14px;
  line-height: 2;
}
.shopBox {
  padding: 0 10px;
  box-sizing: border-box;
}
.HomeBox {
  .van-pull-refresh {
    overflow: unset;
  }
  .searchBox {
    z-index: 10;
    width: 100%;
    padding: 3% 0%;
    background: linear-gradient(left, #71d283, #01aaa3);
    .searchInfo {
      width: 90%;
      margin: 0 auto;
      padding: 0.3rem 0.5rem;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 10px;
      background-color: #ffffff;
      position: relative;
      .searchText {
        display: inline-block;
        margin-left: 30px;
        font-size: 14px;
        background-color: #ffffff;
        color: #a7a7a7;
        line-height: 25px;
      }
      .searchLogo {
        margin: 0;
        padding: 0;
        position: absolute;
        justify-content: center;
        -webkit-justify-content: center;
        left: 7px;
        top: 7px;
        @include publicFlex;
        .searchLogoIcon {
          width: 21px;
          height: 21px;
          object-fit: cover;
          -webkit-object-fit: cover;
          // background-color: #434343;
        }
      }
    }
  }
  .annunciateBox {
    text-align: left;
    // padding: {
    //   top: (26px/3);
    //   left: (45px/3);
    //   right: (45px/3);
    // }
    padding: {
      top: 10.5px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
    box-sizing: border-box;
    .annunciateInfo {
      position: relative;
      margin-top: 7px;
      .annunciateBg {
        margin: 0;
        padding: 0;
        .annunciateBgImg {
          width: 100%;
        }
      }
      .annunciateMsg {
        width: 279px;
        font-size: 12px;
        font-family: Helvetica;
        font-weight: bold;
        line-height: 18px;
        position: absolute;
        top: 11%;
        left: 11%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        white-space: normal;
      }
    }
  }
  // 商品列表
  ::v-deep .shopListBox {
    margin-top: 20px;
    .shopListTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 (40px/3);
      padding: 0 10px;
      // margin-bottom: (45px/3);
      margin-bottom: 14.5px;
      .shopListTitleLeft {
        font-size: 15px;
        color: #000000;
        font-family: Helvetica85-Heavy;
        font-weight: 800;
      }
      .shopListTitleRight {
        color: #1eb326;
        font-size: 14px;
      }
    }
    // 商品列表
    .shopList {
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      @include publicUl;
      @include publicFlex;
      .shopListItem {
        width: 47%;
        margin-bottom: 2%;
        border-radius: 6px;
        @include publicBoxRadio;
        ::v-deep .shopInfo {
          margin-top: 6.66px;
        }
        &:nth-child(2n + 1) {
          margin-left: 2%;
        }
        &:nth-child(2n) {
          margin-left: 2%;
        }
        // &:nth-child(2n + 3) {
        //   // margin-top: -20px;
        // }
        &:nth-child(2n + 4) {
          //margin-top: -(40px/3);
        }
        &:first-child {
          ::v-deep .shopInfo {
            // margin-top: 0;
            //margin-top: 20px;
          }
        }
        &:first-child(2) {
          ::v-deep .shopInfo {
            // margin-top: 0;
            margin-top: 0;
          }
        }
      }
    }
  }
}
.commontitle {
  font-size: 14px;
  font-family: HelveticaNeue LT 97 BlackCn;
  font-weight: 400;
  color: #1eb326;
  text-align: left;
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  -webkit-display: flex;
  -webkit-align-items: center;
  -webkit-flex-direction: row-reverse;
}
</style>
