<template>
  <div class="videoItemBox" v-if="videoUrl">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="playerOptions"
      @canplaythrough="hiddnLoading1"
      @ready="hiddnLoading2"
    ></video-player>

    <div class="loading-overlay" v-if="isLoading">
      <van-loading size="50px" color="#48d58e"></van-loading>
    </div>

    <div class="video-progress-outer">
      <div
        class="video-progress-inner"
        :style="{ width: currentProgress + '%' }"
      ></div>
    </div>
    <div class="slides-pager-corner">
      <van-button class="slides-pager-fullscreen" @click="onFullScreen()">
        <van-icon name="expand-o" size="20" />
      </van-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon, Button, Loading } from "vant";
import { isProduction, isHave } from "@environment/type";

Vue.use(Icon)
  .use(Button)
  .use(Loading);
export default {
  name: "VideoItem",
  props: {
    videoUrl: {
      type: String
    }
  },
  data() {
    return {
      currentIndex: 0,
      currentVideo: null,
      currentProgress: 0,
      isLoading: true,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: this.videoUrl // url地址
          }
        ],
        poster: require("@/assets/img/other/register/img_logo@2x.png"), // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: this.$t.meta.videoError, // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controls: false,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: isProduction() ? false : true // 全屏按钮
        }
      }
    };
  },
  created() {
    setTimeout(() => {
      if (this.videoUrl) {
        this.currentVideo = document.getElementsByClassName("vjs-tech")[0];
        this.currentVideo.addEventListener("timeupdate", () => {
          this.currentProgress = Math.floor(
            (100 / this.currentVideo.duration) * this.currentVideo.currentTime
          );
          if (this.currentVideo.currentTime > 0.1) {
            this.isLoading = false;
          }
        });
      }
    }, 500);
  },
  methods: {
    hiddnLoading1() {
      this.isLoading = false;
    },
    hiddnLoading2() {
      let wechatH5 = isHave("MicroMessenger");
      // this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
      //   msg: JSON.stringify(navigator.userAgent),
      //   status: 200
      // });
      if (wechatH5) {
        this.isLoading = false;
      }
    },
    onFullScreen() {
      if (isProduction()) {
        this.currentVideo.webkitEnterFullscreen();
      } else {
        this.currentVideo.requestFullscreen();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep.videoItemBox {
  position: relative;
  .vjs-big-play-button {
    border-radius: 50%;
    width: 2em;
    height: 2em !important;
    line-height: 65px !important;
    left: 55%;
  }
  .loading-overlay {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    img {
      width: 30%;
      height: 30%;
      object-fit: contain;
    }
  }
  .video-progress-outer {
    z-index: 11;
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 2px;

    .video-progress-inner {
      min-width: 0%;
      max-width: 100%;
      transition: width 0.1s;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      width: 0%;
      background: rgb(10, 199, 174);
    }
  }

  .slides-pager-corner {
    & {
      position: absolute;
      bottom: 5%;
      right: 3%;
    }

    .slides-pager-fullscreen {
      color: #fff;
      line-height: 1;
      height: 36px;
      width: 36px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      text-align: center;
    }
  }
}
</style>
