<template>
  <div class="bannerList" v-if="listViewAdverts && listViewAdverts.length > 0">
    <van-swipe class="my-swipe" :autoplay="4000" indicator-color="#1eb326">
      <van-swipe-item v-for="(item, index) in listViewAdverts" :key="index">
        <img
          @click="swipeClick(item.LinkStu, item.OtherLinkStu)"
          class="swipeItem"
          :src="item.ImgUrl && item.ImgUrl"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
export default {
  name: "Banner",
  components: {
    "van-swipe": Swipe,
    "van-swipe-item": SwipeItem
  },
  props: ["listViewAdverts"],
  data() {
    return {
      list: [1, 2, 3]
    };
  },
  methods: {
    swipeClick(ProductNumber, ShopId) {
      if (ShopId) {
        this.getShopId(ShopId);
      }
      if (ProductNumber && ShopId) {
        this.getProductDetails(ProductNumber, ShopId);
      }
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber
          },
          {
            name: "ShopId",
            value: ShopId
          }
        ]
      });
    },
    // 跳转商铺
    getShopId(ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: ShopId
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
// 是设计图是三杯图
.bannerList {
  .my-swipe .van-swipe-item {
    width: 100%;
    .swipeItem {
      width: 100%;
      height: 55.47vw;
      object-fit: cover;
    }
  }
}
</style>
